// General imports
import React, { useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './NewDrinkBottle.css';

const NewDrinkBottle = (props) => {

    const navigate = useNavigate();

    // set state for form info
    const [formInfoBottle, setFormInfoBottle] = useState({
        bottleName: "",
        bottleDescription: "",
        ABV: "",
        category: ""
    })

    // update form info on input change:

    function handleNameChange(e) {
        setFormInfoBottle({
            ...formInfoBottle,
            bottleName: e.target.value
        });
    }

    function handleDescriptionChange(e) {
        setFormInfoBottle({
            ...formInfoBottle,
            bottleDescription: e.target.value
        });
    }

    function handleABVChange(e) {
        setFormInfoBottle({
            ...formInfoBottle,
            ABV: e.target.value
        });
    }

    function handleCategoryChange(e) {
        setFormInfoBottle({
            ...formInfoBottle,
            category: e.target.value
        });
    }

    // add to database on form submit
    const submitHandlerBottle = (e) => {
        axios.post("https://webpub.dixonwebdev.com/webpubAPI/api/drinksbottles/new", formInfoBottle)
            .then(response => {
                console.log(response)
                setFormInfoBottle({
                    bottleName: "",
                    bottleDescription: "",
                    ABV: "",
                    category: ""
                })
                navigate("/admin")
            })
            .catch(err => console.log("Error creating new drink", err))
    }

    return (

        <>
            {/* Add new drink form */}
            <div id="drinkFormContainerBottle">
                <form onSubmit={submitHandlerBottle}>
                    <div className="drinkBottleFormDiv">
                        <label htmlFor="bottleName">Name:</label>
                        <input type="text" id="bottleName" name="bottleName" value={formInfoBottle.bottleName} onChange={handleNameChange} />
                    </div>
                    <div className="drinkBottleFormDiv">
                        <label htmlFor="bottleDescription">Description:</label>
                        <input type="text" id="bottleDescription" name="bottleDescription" value={formInfoBottle.bottleDescription} onChange={handleDescriptionChange} />
                    </div>
                    <div className="drinkBottleFormDiv">
                        <label htmlFor="ABV">ABV:</label>
                        <input type="number" id="ABVbottle" name="ABV" value={formInfoBottle.ABV} onChange={handleABVChange}/>
                    </div>
                    <div className="drinkBottleFormDiv">
                        <label htmlFor="category">Category:</label>
                        <input type="text" id="category" name="category" value={formInfoBottle.category} onChange={handleCategoryChange}/>
                    </div>
                    <div>
                        <button id="bottleSubmitButton" type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </>

    )
}

export default NewDrinkBottle;