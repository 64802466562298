// General imports
import React, { useEffect, useState } from "react";
import axios from 'axios';
import './Drinks.css';

// Image imports
import beerTap from './img/beerTap.png';
import cocktail from './img/WebPubDrink.png';

// Component Imports
import DrinksMobile from "./DrinksMobile";

const Drinks = (props) => {

    // set state for list of drinks (on tap)
    const [drinksTap, setDrinksTap] = useState([])

    // set state for list of drinks (bottles and cans)
    const [drinksBottles, setDrinksBottles] = useState([])

    // import drink menu on page load (on tap)
    useEffect(() => {
        axios.get("https://webpub.dixonwebdev.com/webAPI/api/drinkstap")
            .then(response => {
                console.log(response)
                setDrinksTap(response.data.results)
            })
            .catch(err => console.log("error:", err))
    })

    // import drink menu on page load (bottles and cans)
    useEffect(() => {
        axios.get("https://webpub.dixonwebdev.com/webAPI/api/drinksbottles")
            .then(response => {
                console.log(response)
                setDrinksBottles(response.data.results)
            })
            .catch(err => console.log("error:", err))
    })

    return (

        <>

            <div className="drinksPageDiv">
                <img src={beerTap} alt="beer mug" id="beerMugPic" />
                <h1 id="drinkMenuTitle">Let's Drink!</h1>

                {/* On tap drink table */}
                <div id="onTapDrinkTable">
                    <table>
                        <thead>
                            <tr>
                                <th className="categoryHeader">On Tap</th>
                            </tr>
                        </thead>
                        <tbody className="tableBody">
                            {
                                drinksTap.map((drink, i) => {
                                    return (
                                        <>
                                            <tr>
                                                <td className="drinkName">{drink.name}</td>
                                            </tr>
                                            <tr>
                                                <td className="drinkDescription">{drink.description}</td>
                                            </tr>
                                            <tr>
                                                <td className="drinkABV">{drink.ABV}% ABV</td>
                                            </tr>
                                        </>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>

                {/* bottles and cans drink tables (seperated by category) */}
                <h2 id="bottlesCansHeader">Bottles and Cans</h2>
                <div id="mobileBottlesDisplay">
                    <DrinksMobile />
                </div>
                <div id="bottlesAndCansDrinkTables">

                    {/* cocktail image */}
                    <img src={cocktail} alt="cocktail" id="cocktailImage" />
                    <table id="bcTable">
                        <thead>
                            <tr>
                                <th className="categorySubheader">Craft</th>
                                <th className="categorySubheaderTwo">Domestic</th>
                                <th className="categorySubheaderTwo">Import</th>
                                <th className="categorySubheaderTwo">Seltzers</th>
                                <th className="categorySubheader">Canned Cocktails</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="bottlesTableRow">

                                {/* craft beer */}
                                <td>
                                    {
                                        drinksBottles.filter(drinkCategory => drinkCategory.category === "craft").map((bottle, i) => {
                                            return (
                                                <>
                                                    <div className="bottlesTableData">
                                                        <p className="bottleNameDisplay">{bottle.bottleName}</p>
                                                        <p className="bottleOtherDisplay">{bottle.bottleDescription}</p>
                                                        <p className="bottleOtherDisplay">{bottle.ABV}% ABV</p>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </td>

                                {/* domestic beer */}
                                <td>
                                    {
                                        drinksBottles.filter(drinkCategory => drinkCategory.category === "domestic").map((bottle, i) => {
                                            return (
                                                <>
                                                    <div className="bottlesTableData">
                                                        <p className="bottleNameDisplay">{bottle.bottleName}</p>
                                                        <p className="bottleOtherDisplay">{bottle.bottleDescription}</p>
                                                        <p className="bottleOtherDisplay">{bottle.ABV}% ABV</p>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </td>

                                {/* imports */}
                                <td>
                                    {
                                        drinksBottles.filter(drinkCategory => drinkCategory.category === "import").map((bottle, i) => {
                                            return (
                                                <>
                                                    <div className="bottlesTableData">
                                                        <p className="bottleNameDisplay">{bottle.bottleName}</p>
                                                        <p className="bottleOtherDisplay">{bottle.bottleDescription}</p>
                                                        <p className="bottleOtherDisplay">{bottle.ABV}% ABV</p>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </td>

                                {/* seltzers */}
                                <td>
                                    {
                                        drinksBottles.filter(drinkCategory => drinkCategory.category === "seltzers").map((bottle, i) => {
                                            return (
                                                <>
                                                    <div className="bottlesTableData">
                                                        <p className="bottleNameDisplay">{bottle.bottleName}</p>
                                                        <p className="bottleOtherDisplay">{bottle.bottleDescription}</p>
                                                        <p className="bottleOtherDisplay">{bottle.ABV}% ABV</p>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </td>

                                {/* canned cocktails */}
                                <td>
                                    {
                                        drinksBottles.filter(drinkCategory => drinkCategory.category === "cannedCocktails").map((bottle, i) => {
                                            return (
                                                <>
                                                    <div className="bottlesTableData">
                                                        <p className="bottleNameDisplay">{bottle.bottleName}</p>
                                                        <p className="bottleOtherDisplay">{bottle.bottleDescription}</p>
                                                        <p className="bottleOtherDisplay">{bottle.ABV}% ABV</p>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </>
    )
}

export default Drinks;