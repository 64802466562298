// General imports
import React from "react";
import { Link } from "react-router-dom";
import './Menu.css';

const Menu = (hamburgerstatus, sethamburgerstatus) => {

    return (

        <>

            <div className="menuPageDiv">
                <div className="burgerLinks">
                    <Link to="/" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Home</Link>
                    <Link to="/drinks" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Menu</Link>
                    <Link to="/about" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>About</Link>
                    <Link to="/contact" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Contact</Link>
                </div>
            </div>

        </>

    )
}

export default Menu;