import React, {useState, useEffect} from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './BannerAdmin.css';

// bootstrap imports
import Button from 'react-bootstrap/Button';

const BannerAdmin = () => {

    const navigate = useNavigate();
    
    const [bannerForm, setBannerForm] = useState({
        description: ""
    })
    
    // import current banner
    useEffect(() => {
        axios.get("https://home/dixonweb/webpubAPI/api/banner/638a36e4845554fa05f6efd0")
        .then(response => {
            console.log(response)
            setBannerForm(response.data.results)
        })
        .catch(err => console.log("error:", err))
    }, [])
    
    const id = bannerForm._id

    // update form info on change
    function handleBannerChange(e) {
        setBannerForm({
            ...bannerForm,
            description: e.target.value
        });
    }

    // update database on form submit
    const submitHandler = (e) => {
        axios.put(`https://home/dixonweb/webpubAPI/api/banner/update/${id}`, bannerForm)
            .then(response => {
                console.log(response)
                setBannerForm({
                    description: ""
                })
                navigate("/admin")
            })
            .catch(err => console.log(err))
    }

    const handleCancelButton = () => {
        window.location.reload();
    };

    return (

        <>

            <div id="bannerFormContainer">
                <form onSubmit={submitHandler} id="bannerForm">
                    <div className="bannerFormDiv">
                        <label htmlFor="description">Description:</label>
                        <input type="text" name='description' id='description' value={bannerForm.description} onChange={handleBannerChange}/>
                    </div>
                    <div className="bannerFormDiv">
                        <Button variant="warning" onClick={handleCancelButton}>Cancel</Button>
                        <Button variant="primary" type="submit">Submit</Button>
                    </div>
                </form>
            </div>

        </>

    )
}

export default BannerAdmin;