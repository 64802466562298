// General imports
import React from "react";
import './About.css';

// Image imports
import aboutBackground from './img/WebPubOutside.png';

const About = (props) => {

    return (

        <>

            <div id="headerDiv">
                <h1>About Us</h1>
            </div>

            <div className="aboutPageDiv">
                <img src={aboutBackground} alt="Web Pub building" id="aboutBackground"/>
                <div id="aboutText">
                    <p>Web Pub Bucktown started laying it's roots in 1947 with the name Club Gardenia. That magical pub set the bar high for us and we will not disappoint.  In 2019 Mike and Aurora Burke passed the torch to us and we will continue to focus on our community, our neighbors, our family! Stop by for a pint of our ever-changing beer list or craft cocktails.  See you soon!</p>
                    <p>David & Eric</p>
                </div>
            </div>

        </>

    )
}

export default About;