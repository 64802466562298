// General imports
import React, { useState } from "react";
import './Contact.css';

// component imports
import ContactForm from './ContactForm';

const Contact = (props) => {

    const [contactFormEnabled, setContactFormEnabled] = useState(false);

    // show contact form on button click
    function handleContactRender() {
        setContactFormEnabled(!contactFormEnabled);
    }

    return (

        <>

            <div id="contactHeader">
                <h1>Contact</h1>
            </div>
            <div className="contactPageDiv">
                {contactFormEnabled ? <ContactForm /> : null}
                <div className="contactCol">
                    <div className="contactInfoDiv contactTop">
                        <button className="contactButton" onClick={handleContactRender}>Email</button>
                        <p>contact@webpubbucktown.com</p>
                    </div>
                    <div className="contactInfoDiv">
                        <a href="tel:6306212514" className="contactButton call">Call</a>
                        <p>(773) 276-3411</p>
                    </div>
                </div>
                <div className="contactCol">
                    <h2 id="contactHours">Hours</h2>
                    <div id="hoursDiv">
                        <div className="hoursDivContent">
                            <p>Monday - Friday:</p>
                            <p>12pm - 2am</p>
                        </div>
                        <div className="hoursDivContent">
                            <p>Saturday:</p>
                            <p>12pm - 3am</p>
                        </div>
                        <div className="hoursDivContent">
                            <p>Sunday:</p>
                            <p>12pm - 2am</p>
                        </div>
                    </div>
                </div>
                <div id="mobileContactInfo">
                    <div className="mobileContactDiv">
                        <button className="contactButton" onClick={handleContactRender}>Email</button>
                        <p>contact@webpubbucktown.com</p>
                    </div>
                    <div className="mobileContactDiv">
                        <a href="tel:6306212514" className="contactButton call">Call</a>
                        <p>(773) 276-3411</p>
                    </div>
                </div>
                <div className="contactPageMapDiv">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2968.6679301108406!2d-87.68103578449366!3d41.92149677921882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fd2935b07ebc7%3A0x22240ad54a876df4!2sWeb%20Pub%20Bucktown!5e0!3m2!1sen!2sus!4v1672856125471!5m2!1sen!2sus"
                        width="1400"
                        height="600"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        id="desktopIframe"
                        title="desktopIframe"></iframe>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2968.6679301108406!2d-87.68103578449366!3d41.92149677921882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fd2935b07ebc7%3A0x22240ad54a876df4!2sWeb%20Pub%20Bucktown!5e0!3m2!1sen!2sus!4v1672856125471!5m2!1sen!2sus"
                        width="700"
                        height="600"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        id="tabletIframe"
                        title="tabletIframe"></iframe>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2968.6679301108406!2d-87.68103578449366!3d41.92149677921882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fd2935b07ebc7%3A0x22240ad54a876df4!2sWeb%20Pub%20Bucktown!5e0!3m2!1sen!2sus!4v1672856125471!5m2!1sen!2sus"
                        width="500"
                        height="450"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        id="tabletIframeTwo"
                        title="tabletIframeTwo"></iframe>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2968.6679301108406!2d-87.68103578449366!3d41.92149677921882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fd2935b07ebc7%3A0x22240ad54a876df4!2sWeb%20Pub%20Bucktown!5e0!3m2!1sen!2sus!4v1672856125471!5m2!1sen!2sus"
                        width="380"
                        height="400"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        id="mobileIframe"
                        title="mobileIframe"></iframe>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2968.6679301108406!2d-87.68103578449366!3d41.92149677921882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fd2935b07ebc7%3A0x22240ad54a876df4!2sWeb%20Pub%20Bucktown!5e0!3m2!1sen!2sus!4v1672856125471!5m2!1sen!2sus"
                        width="360"
                        height="400"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        id="mobileIframeTwo"
                        title="mobileIframeTwo"></iframe>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2968.6679301108406!2d-87.68103578449366!3d41.92149677921882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fd2935b07ebc7%3A0x22240ad54a876df4!2sWeb%20Pub%20Bucktown!5e0!3m2!1sen!2sus!4v1672856125471!5m2!1sen!2sus"
                        width="345"
                        height="360"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        id="mobileIframeThree"
                        title="mobileIframeThree"></iframe>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2968.6679301108406!2d-87.68103578449366!3d41.92149677921882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fd2935b07ebc7%3A0x22240ad54a876df4!2sWeb%20Pub%20Bucktown!5e0!3m2!1sen!2sus!4v1672856125471!5m2!1sen!2sus"
                        width="330"
                        height="370"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        id="mobileIframeFour"
                        title="mobileIframeFour"></iframe>
                </div>
            </div>

        </>

    )
}

export default Contact;