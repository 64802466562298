import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import './EditDrinkTap.css';

// bootstrap imports
import Button from 'react-bootstrap/Button';

const EditDrinkTap = (props) => {

    const {id} = useParams();

    const navigate =  useNavigate();

    // set state for form info
    const [formInfoTap, setFormInfoTap] = useState({
        name:"",
        description: "",
        ABV: ""
    })

    // import form info on page load
    useEffect(() => {
        axios.get(`https://webpub.dixonwebdev.com/webpubAPI/api/drinkstap/${id}`)
            .then(response => {
                console.log(response)
                setFormInfoTap(response.data.results)
            })
            .catch(err => console.log(err))
    })

    // update form info on change
    const onChangeHandler = (e) => {
        setFormInfoTap({
            ...formInfoTap,
            [e.target.name]: e.target.value,
            [e.target.description]: e.target.value,
            [e.target.ABV]: e.target.value
        })
    }

    // update database on form submit
    const submitHandler = (e) => {
        e.preventDefault()
        axios.put(`https://webpub.dixonwebdev.com/webpubAPI/api/drinkstap/update/${id}`, formInfoTap)
            .then(response => {
                console.log(response)
                setFormInfoTap({
                    name: "",
                    description: "",
                    ABV: ""
                })
                navigate("/admin")
            })
            .catch(err=>console.log(err))
    }

    return (

        <>

            <h2>Edit Drink Form</h2>

            {/* Edit drink form */}
            <div className="editTapContainer">
                <form onSubmit={submitHandler}>
                    <div>
                        <label htmlFor='name'>Name:</label>
                        <input type="text" name="name" id="name" value={formInfoTap.name} onChange={onChangeHandler}></input>
                    </div>
                    <div>
                        <label htmlFor="description">Description:</label>
                        <input type="text" name='description' id='description' value={formInfoTap.description} onChange={onChangeHandler}/>
                    </div>
                    <div>
                        <label htmlFor="ABV">ABV:</label>
                        <input type="number" name='ABV' id='ABV' value={formInfoTap.ABV} onChange={onChangeHandler}/>
                    </div>
                    <div>
                        <Button variant="warning"><Link className='editDrinkTapLink' to = "/admin">Cancel</Link></Button>
                        <Button type="submit" variant="primary">Submit</Button>
                    </div>
                </form>
            </div>
        </>

    )
}

export default EditDrinkTap;