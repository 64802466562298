import React, { useState } from 'react';
import axios from 'axios';
import './ImageUpload.css';

const ImageUpload = () => {

    const [image, setImage] = useState({ photo: '' })
    
    function handlePhotoChange(e) {
        setImage({
            ...image,
            photo: e.target.files[0]
        });
    }

    const handleSubmitImage = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('photo', image.photo);

        axios.post('https://webpub.dixonwebdev.com/webpubAPI/image/add', formData)
            .then(res => {
                console.log(res);
                alert("New image added");
                window.location.reload(false);
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <>
            <div className="imageUploadContainer">
                <form onSubmit={handleSubmitImage} encType='multipart/form-data'>
                    <input
                        type='file'
                        accept='.png, .jpg, .jpeg'
                        name='photo'
                        onChange={handlePhotoChange}
                    />
                    <button type='submit' className='imageInput'>Upload Image</button>
                </form>
            </div>
        </>
    )
};

export default ImageUpload;