// General imports
import React from "react";
import './HomepageMarquee.css';

// Image imports
import BrookeWeb from './img/BrookeWebPic.png';
import DavidWeb from './img/DavidWebPic.png';
import EricWeb from './img/EricWebPic.png';
import WebPubPic from './img/WebPubBarPic.jpg';
import WebPubPicTwo from './img/WebPubBarPictwo.png';
import WebPubDrink from './img/WebPubDrink.png';

const HomepageMarquee = () => {
    return (
        <>

            {/* Add functionality to allow David/Eric to add or remove pictures from the marquee */}

            <img src={BrookeWeb} alt="Web Pub Pic" className="marqueePic" />
            <img src={WebPubPicTwo} alt="Web Pub Pic" className="marqueePic" />
            <img src={DavidWeb} alt="Web Pub Pic" className="marqueePic" />
            <img src={WebPubDrink} alt="Web Pub Pic" className="marqueePic" />
            <img src={EricWeb} alt="Web Pub Pic" className="marqueePic" />
            <img src={WebPubPic} alt="Web Pub Pic" className="marqueePic" />
        </>
    )
};

export default HomepageMarquee;