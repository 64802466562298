import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "./Login.css";

const Login = () => {

    const navigate = useNavigate();

    const [loginInfo, setLoginInfo] = useState({
        username: "",
        password: ""
    })

    const [errors, setErrors] = useState({
        username: "",
        password: ""
    })

    function loginChangeHandlerName(e) {
        setLoginInfo({
            ...loginInfo,
            username: e.target.value
        })
    }

    function loginChangeHandlerPassword(e) {
        setLoginInfo({
            ...loginInfo,
            password: e.target.value
        })
    }

    const login = (e) => {
        e.preventDefault();
        axios.post("https://webpub.dixonwebdev.com/webpubAPI/api/login", loginInfo, { withCredentials: true })
            .then(res => {
                console.log("Login:", res)
                if (res.data.msg === "login successful") {
                    navigate("/admin")
                } else {
                    console.log("response from login attempt", res);
                    setErrors(res.data.errors)
                }
            })
            .catch(err => console.log(err))
    }

    return (

        <div className="loginContainer">
            <h2>Please log in</h2>
            <form onSubmit={login} className="form-inline">
                <div className="form-group d-flex loginInput">
                    <label htmlFor="username">Username:</label>
                    <input onChange={loginChangeHandlerName} type="text" className="form-control" id="username" name='username' value={loginInfo.username}/>
                    {errors.username ? <p className="text-danger">{errors.username.message}</p> : ""}
                </div>
                <div className="form-group d-flex loginInput">
                    <label htmlFor="password" id='passwordLabel'>Password: </label>
                    <input onChange={loginChangeHandlerPassword} type="password" className="form-control" id="password" name='password' value={loginInfo.password}/>
                    {errors.password ? <p className="text-danger">{errors.password.message}</p> : ""}
                </div>
                <button type='submit' id='loginSubmitButton'>Log In</button>
            </form>
        </div>
    );
};

export default Login;