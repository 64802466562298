// General Imports
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

// Component Imports
import About from './components/About';
import Admin from './components/Admin';
import Contact from './components/Contact';
import Drinks from './components/Drinks';
import EditDrinkBottle from './components/EditDrinkBottle';
import EditDrinkTap from './components/EditDrinkTap';
import Footer from './components/Footer';
import Home from './components/Home';
import Login from './components/Login';
import Menu from './components/Menu';
import Navbar from './components/Navbar';
import Register from "./components/Register";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home></Home>}></Route>
          <Route path="/about" element={<About />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/drinks/edit/:id" element={<EditDrinkTap />} />
          <Route path="/drinksBottle/edit/:id" element={<EditDrinkBottle />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/drinks" element={<Drinks />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register/>}/>
          <Route path="/menu" element={<Menu />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
