// General imports
import React from "react";
import Marquee from 'react-fast-marquee';
import './Home.css';

// Component Imports
import Drinks from "./Drinks";
import About from "./About";
import Contact from './Contact';
import HomepageMarquee from "./HomepageMarquee";
// import Banner from './Banner';

// Image Imports
import Background from './img/WebPubBackground.png';

const Home = (props) => {

    return (

        <>

            <div className="homePageDiv">

                {/* Web Pub Header and Subheader */}
                <div id="homePageHeader">
                    <h1>WEB PUB BUCKTOWN</h1>
                    <h4>Happy Hour. Every Hour.</h4>
                </div>

                {/* Background Image */}
                <img src={Background} alt="Background, beer taps" id="backgroundImage" />
                <div id="overlay"/>

                {/* Banner Div */}
                {/* <div id="homePageBannerDiv">
                    <Marquee gradientWidth="20" speed="45">
                        <Banner/>
                    </Marquee>
                </div> */}

            </div>

            {/* Drinks Menu */}
            <Drinks></Drinks>

            {/* About Page */}
            <About></About>

            {/* Contact Page */}
            <Contact></Contact>
            
            {/* Web Pictures */}
            <div id="PictureDiv">
                <Marquee gradientWidth="20">
                    <HomepageMarquee/>
                </Marquee>
            </div>

        </>

    )
}

export default Home;