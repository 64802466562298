// General imports
import React from "react";
import './ContactForm.css';

// image imports
import Logo from './img/WebPubLogo.png';

const ContactForm = () => {

    return (
        <>
            <div id="contactFormDiv">
                <div className="webLogoCol">
                    <img src={Logo} alt="webpub logo" id="webLogo" />
                </div>
                <div className="contactFormCol">
                    <a href="/contact" id="closeButton">X</a>
                    <form action="#" id="contactForm">
                        <div className="formElement topElement">
                            <label htmlFor="name">Name:</label>
                            <input type="text" name="name" id="name" />
                        </div>
                        <div className="formElement">
                            <label htmlFor="email">Email:</label>
                            <input type="email" name="email" id="email" />
                        </div>
                        <div id="messageDiv">
                            <label htmlFor="message" id="messageLabel">Message:</label>
                            <textarea name="message" id="message" cols="60" rows="10"></textarea>
                            <label htmlFor="messageTablet" id="messageTabletLabel">Message: </label>
                            <textarea name="messageTablet" id="messageTablet" cols="50" rows="10"></textarea>
                            <label htmlFor="messageMobile" id="messageMobileLabel">Message: </label>
                            <textarea name="messageMobile" id="messageMobile" cols="40" rows="10"></textarea>
                            <label htmlFor="messageMobileTwo" id="messageMobileLabelTwo">Message: </label>
                            <textarea name="messageMobileTwo" id="messageMobileTwo" cols="35" rows="9"></textarea>
                        </div>
                        <div className="formElement">
                            <button type="submit" id="submitButton">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ContactForm;