// General imports
import React, { useEffect, useState } from "react";
import axios from 'axios';
import './DrinksMobile.css';

const DrinksMobile = () => {

    // set state for list of drinks (bottles and cans)
    const [drinksBottles, setDrinksBottles] = useState([])

    // import drink menu on page load (bottles and cans)
    useEffect(() => {
        axios.get("https://webpub.dixonwebdev.com/webAPI/api/drinksbottles")
            .then(response => {
                console.log(response)
                setDrinksBottles(response.data.results)
            })
            .catch(err => console.log("error:", err))
    })

    return (

        <>

            <div className="mobileDrinkTablesRow mobileTopRow">

                {/* cocktail image */}
                <table>
                    <thead>
                        <tr>
                            <th className="mobileCategoryHeaderName">Craft</th>
                            <th className="mobileCategoryHeader">Domestic</th>
                            <th className="mobileCategoryHeader">Import</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr id="mobileTableRow">

                            {/* craft beer */}
                            <td>
                                {
                                    drinksBottles.filter(drinkCategory => drinkCategory.category === "craft").map((bottle, i) => {
                                        return (
                                            <>
                                                <div className="mobileTableData">
                                                    <p className="mobileNameDisplay">{bottle.bottleName}</p>
                                                    <p className="mobileOtherDisplay">{bottle.bottleDescription}</p>
                                                    <p className="mobileOtherDisplay">{bottle.ABV}% ABV</p>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </td>

                            {/* domestic beer */}
                            <td>
                                {
                                    drinksBottles.filter(drinkCategory => drinkCategory.category === "domestic").map((bottle, i) => {
                                        return (
                                            <>
                                                <div className="mobileTableData">
                                                    <p className="mobileNameDisplay">{bottle.bottleName}</p>
                                                    <p className="mobileOtherDisplay">{bottle.bottleDescription}</p>
                                                    <p className="mobileOtherDisplay">{bottle.ABV}% ABV</p>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </td>

                            {/* imports */}
                            <td>
                                {
                                    drinksBottles.filter(drinkCategory => drinkCategory.category === "import").map((bottle, i) => {
                                        return (
                                            <>
                                                <div className="mobileTableData">
                                                    <p className="mobileNameDisplay">{bottle.bottleName}</p>
                                                    <p className="mobileOtherDisplay">{bottle.bottleDescription}</p>
                                                    <p className="mobileOtherDisplay">{bottle.ABV}% ABV</p>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="mobileDrinkTablesRow mobileBottomRow">
                <table>
                    <thead>
                        <tr>
                            <th className="mobileCategoryHeader seltzersMobile">Seltzers</th>
                            <th className="mobileCategoryHeaderName">Canned Cocktails</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr id="bottlesTableRow">

                            {/* seltzers */}
                            <td>
                                {
                                    drinksBottles.filter(drinkCategory => drinkCategory.category === "seltzers").map((bottle, i) => {
                                        return (
                                            <>
                                                <div className="mobileTableData">
                                                    <p className="mobileNameDisplay">{bottle.bottleName}</p>
                                                    <p className="mobileOtherDisplay">{bottle.bottleDescription}</p>
                                                    <p className="mobileOtherDisplay">{bottle.ABV}% ABV</p>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </td>

                            {/* canned cocktails */}
                            <td>
                                {
                                    drinksBottles.filter(drinkCategory => drinkCategory.category === "cannedCocktails").map((bottle, i) => {
                                        return (
                                            <>
                                                <div className="mobileTableData">
                                                    <p className="mobileNameDisplay">{bottle.bottleName}</p>
                                                    <p className="mobileOtherDisplay">{bottle.bottleDescription}</p>
                                                    <p className="mobileOtherDisplay">{bottle.ABV}% ABV</p>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </>

    )
}

export default DrinksMobile;