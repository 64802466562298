import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import './EditDrinkBottle.css';

// bootstrap imports
import Button from 'react-bootstrap/Button';

const EditDrinkBottle = (props) => {

    const { id } = useParams();

    const navigate = useNavigate();

    // set state for form info
    const [formInfoBottle, setFormInfoBottle] = useState({
        bottleName: "",
        bottleDescription: "",
        ABV: "",
        category: ""
    })

    // import form info on page load
    useEffect(() => {
        axios.get(`https://webpub.dixonwebdev.com/webpubAPI/api/drinksbottles/${id}`)
            .then(response => {
                console.log(response)
                setFormInfoBottle(response.data.results)
            })
            .catch(err => console.log(err))
    })

    // update database on form submit
    const submitHandlerBottle = (e) => {
        e.preventDefault()
        axios.put(`https://webpub.dixonwebdev.com/webpubAPI/api/drinksbottles/update/${id}`, formInfoBottle)
            .then(response => {
                console.log(response)
                setFormInfoBottle({
                    bottleName: "",
                    bottleDescription: "",
                    ABV: "",
                    category: ""
                })
                navigate("/admin")
            })
            .catch(err => console.log(err))
    }

    // input change handlers
    function handleNameChange(e) {
        setFormInfoBottle({
            ...formInfoBottle,
            bottleName: e.target.value
        });
    }

    function handleDescriptionChange(e) {
        setFormInfoBottle({
            ...formInfoBottle,
            bottleDescription: e.target.value
        });
    }

    function handleABVChange(e) {
        setFormInfoBottle({
            ...formInfoBottle,
            ABV: e.target.value
        });
    }

    function handleCategoryChange(e) {
        setFormInfoBottle({
            ...formInfoBottle,
            category: e.target.value
        });
    }

    return (

        <>

            <h2>Edit Drink Form</h2>

            {/* Edit drink form */}
            <div className="editBottleContainer">
                <form onSubmit={submitHandlerBottle}>
                    <div>
                        <label htmlFor='bottleName'>Name:</label>
                        <input type="text" name="bottleName" id="bottleName" value={formInfoBottle.bottleName} onChange={handleNameChange}></input>
                    </div>
                    <div>
                        <label htmlFor="bottleDescription">Description:</label>
                        <input type="text" name='bottleDescription' id='bottleDescription' value={formInfoBottle.bottleDescription} onChange={handleDescriptionChange} />
                    </div>
                    <div>
                        <label htmlFor="ABV">ABV:</label>
                        <input type="number" name='ABV' id='ABV' value={formInfoBottle.ABV} onChange={handleABVChange} />
                    </div>
                    <div>
                        <label htmlFor="category">Category:</label>
                        <input type="text" name='category' id='category' value={formInfoBottle.category} onChange={handleCategoryChange} />
                    </div>
                    <div>
                        <Button variant="warning"><Link className='editDrinkBottleLink' to="/admin">Cancel</Link></Button>
                        <Button type="submit" variant="primary">Submit</Button>
                    </div>
                </form>
            </div>
        </>

    )
}

export default EditDrinkBottle;