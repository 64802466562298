// General imports
import React from "react";
import './Footer.css';

const Footer = (props) => {

    return (

        <>

            <div className="footerPageDiv">
                <div className="footerCol">
                    <p>Web Pub Bucktown</p>
                    <p>Website by <a href="http://www.dixonwebdev.com" id="DixonLink">Dixon Web Development</a></p>
                    <div id="copyrightMobile">
                        <p>Copyright &copy; 2023 Web Pub Bucktown.</p>
                        <p>All rights reserved.</p>
                    </div>
                </div>
                <div className="footerCol footerCol2">
                    <div id="FooterLinks">
                        <p><a className="FooterLink" href="/privacy">Privacy</a></p>
                        <p id="FooterBreak"> | </p>
                        <p><a className="FooterLink" href="/sitemap">Site Map</a></p>
                    </div>
                    <p id="copyright">Copyright &copy; 2023 Web Pub Bucktown. All rights reserved.</p>
                </div>
            </div>

        </>

    )
}

export default Footer;