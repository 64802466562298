// General imports
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useNavigate } from "react-router-dom";
import './Admin.css';

// component imports
import NewDrinkTap from "./NewDrinkTap";
import EditDeleteTap from "./EditDeleteTap";
import NewDrinkBottle from "./NewDrinkBottle";
import EditDeleteBottle from "./EditDeleteBottle";
import BannerAdmin from "./BannerAdmin";
import ImageUpload from "./ImageUpload";

const Admin = (props) => {

    const navigate = useNavigate();

    const [loggedinuser, setLoggedInUser] = useState({})

    // Import user data on page load, redirect to login page if user not authorized
    useEffect(() => {
        axios.get("https://webpub.dixonwebdev.com/webAPI/api/users/getloggedinuser", { withCredentials: true })
            .then(res => {
                console.log("logged in user:", res)
                setLoggedInUser(res.data)
            })
            .catch(err => {
                navigate("/login")
                console.log("login error", err)
            })
    })

    // Logout and redirect to home page when clicking logout button
    const onclickHandler = (e) => {
        axios.get("https://webpub.dixonwebdev.com/webpubAPI/api/users/logout", { withCredentials: true })
            .then(navigate("/"))
            .catch(err => {
                console.log("error:", err)
            })
    }

    return (

        <>

            <div id="adminPageDiv">

                <div id="welcomeDiv">
                    <h3>Welcome, {loggedinuser.username}</h3>
                    <button className="btn btn-primary" onClick={onclickHandler}>Logout</button>
                </div>

                <Tabs>
                    <TabList>
                        <Tab>On Tap</Tab>
                        <Tab>Bottles and Cans</Tab>
                        <Tab>Homepage Display</Tab>
                        <Tab>Images</Tab>
                    </TabList>

                    <TabPanel>
                        <div className="adminDrinkContainer">
                            <h1>On Tap</h1>
                            <h4>Add a drink:</h4>
                            <br />
                            <NewDrinkTap />
                            <EditDeleteTap />
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="adminDrinkContainer">
                            <h1>Bottles and Cans</h1>
                            <h4>Add a drink:</h4>
                            <br />
                            <NewDrinkBottle />
                            <EditDeleteBottle />
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <h1 id="homepageDisplayDiv">Homepage Display</h1>
                        <BannerAdmin />
                    </TabPanel>

                    <TabPanel>
                        <ImageUpload />
                    </TabPanel>
                </Tabs>
            </div>

        </>

    )
}

export default Admin;