import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Register.css';

const Register = () => {

    const navigate = useNavigate();

    // state variables

    const [registerInfo, setRegisterInfo] = useState({
        username: "",
        email: "",
        password: "",
        confirm: ""
    })

    const [errors, setErrors] = useState({
        username: "",
        email: "",
        password: "",
        confirm: ""
    })

    // input change handlers

    function regChangeHandlerName(e) {
        setRegisterInfo({
            ...registerInfo,
            username: e.target.value
        })
    }

    function regChangeHandlerEmail(e) {
        setRegisterInfo({
            ...registerInfo,
            email: e.target.value
        })
    }

    function regChangeHandlerPassword(e) {
        setRegisterInfo({
            ...registerInfo,
            password: e.target.value
        })
    }

    function regChangeHandlerConfirm(e) {
        setRegisterInfo({
            ...registerInfo,
            confirm: e.target.value
        })
    }

    // axios call (register)

    const register = (e) => {
        e.preventDefault();
        axios.post("https://webpub.dixonwebdev.com/webpubAPI/api/register", registerInfo, { withCredentials: true })
            .then(res => {
                console.log("response from registering", res);
                if (res.data.errors) {
                    setErrors(res.data.errors)
                } else {
                    console.log("success!")
                    navigate("/admin")
                }
            })
            .catch(err => console.log(err))
    }

    return (
        <>
            <div className="registerContainer">
                <form onSubmit={register}>
                    <div className="form-group">
                        <label htmlFor='username'>User Name</label>
                        <input onChange={regChangeHandlerName} type="text" className="form-control" name='username' value={registerInfo.username} />
                        {errors.username ? <p className="text-danger">{errors.username.message}</p> : ""}
                    </div>
                    <div className="form-group">
                        <label htmlFor='email'>Email</label>
                        <input onChange={regChangeHandlerEmail} type="text" className="form-control" name='email' value={registerInfo.email} />
                        {errors.email ? <p className="text-danger">{errors.email.message}</p> : ""}

                    </div>
                    <div className="form-group">
                        <label htmlFor='password'>Password</label>
                        <input onChange={regChangeHandlerPassword} type="password" className="form-control" name='password' value={registerInfo.password} />
                        {errors.password ? <p className="text-danger">{errors.password.message}</p> : ""}

                    </div>
                    <div className="form-group">
                        <label htmlFor='confirm'>Confirm Password</label>
                        <input onChange={regChangeHandlerConfirm} type="password" className="form-control" name='confirm' value={registerInfo.confirm} />
                        {errors.confirm ? <p className="text-danger">{errors.confirm.message}</p> : ""}

                    </div>
                    <button type='submit'>Sign up</button>
                </form>
            </div>
        </>
    );
};

export default Register;