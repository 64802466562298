// General imports
import React, { useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './NewDrinkTap.css';

const NewDrinkTap = (props) => {

    const navigate = useNavigate();

    // set state for form info
    const [formInfoTap, setFormInfoTap] = useState({
        name: "",
        description: "",
        ABV: ""
    })

    // update form info on change
    const onChangeHandlerTap = (e) => {
        setFormInfoTap({
            ...formInfoTap,
            [e.target.name]: e.target.value,
            [e.target.description]: e.target.value,
            [e.target.ABV]: e.target.value
        })
    }

    // add to database on form submit
    const submitHandlerTap = (e) => {
        axios.post("https://webpub.dixonwebdev.com/webpubAPI/api/drinkstap/new", formInfoTap)
            .then(response => {
                console.log(response)
                setFormInfoTap({
                    name: "",
                    description: "",
                    ABV: ""
                })
                navigate("/admin")
            })
            .catch(err => console.log("Error creating new drink", err))
    }

    return (

        <>
            {/* Add new drink form */}
            <div id="drinkFormContainerTap">
                <form onSubmit={submitHandlerTap}>
                    <div className="drinkTapFormDiv">
                        <label htmlFor="name">Name:</label>
                        <input type="text" id="nameTap" name="name" value={formInfoTap.name} onChange={onChangeHandlerTap} />
                    </div>
                    <div className="drinkTapFormDiv">
                        <label htmlFor="description">Description:</label>
                        <input type="text" id="descriptionTap" name="description" value={formInfoTap.description} onChange={onChangeHandlerTap} />
                    </div>
                    <div className="drinkTapFormDiv">
                        <label htmlFor="ABV">ABV:</label>
                        <input type="number" id="ABV" name="ABV" value={formInfoTap.ABV} onChange={onChangeHandlerTap}/>
                    </div>
                    <div>
                        <button id="tapSubmitButton" type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </>

    )
}

export default NewDrinkTap;