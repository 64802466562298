// general imports
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './EditDeleteBottle.css';

// bootstrap imports
import Button from 'react-bootstrap/Button';

const EditDeleteBottle = () => {

    // set state for list of drinks
    const [allDrinksBottle, setAllDrinksBottle] = useState([])

    // set state for delete button
    const [deleteToggleBottle, setDeleteToggleBottle] = useState(false)

    // import all drinks from API upon page load
    useEffect(() => {
        axios.get("https://webpub.dixonwebdev.com/webpubAPI/api/drinksbottles")
            .then(response => {
                console.log(response)
                setAllDrinksBottle(response.data.results)
            })
            .catch(err => console.log("error:", err))
    }, [deleteToggleBottle])

    // delete button function
    const deleteDrinkBottle = (e, id) => {
        console.log("deleting drink", id)
        axios.delete(`https://webpub.dixonwebdev.com/webpubAPI/api/drinksbottles/delete/${id}`)
            .then(response => {
                console.log("delete succesfull", response)
                setDeleteToggleBottle(!deleteToggleBottle)
            })
            .catch(err => console.log(err))
    }

    return (

        <>

            <h4>Edit / Delete Drinks:</h4>
            <br/>

            <div id="editDeleteContainerBottle">
                <table>
                    <thead>
                        <tr>
                            <th id='bottleNameTableHeader'>Drink Name:</th>
                            <th className='bottleTableHeader'>Category:</th>
                            <th className='bottleTableHeader'>Actions Available:</th>
                        </tr>
                    </thead>
                    <tbody id='bottleTableBody'>
                        {
                            allDrinksBottle.map((drink, i) => {
                                return (
                                    <tr key={drink._id}>
                                        <td className='bottleTableName'>{drink.bottleName}</td>
                                        <td className='bottleTableData'>{drink.category}</td>
                                        <td className='bottleTableData'><Button variant="success"><Link className='bottleLink' to={`/drinksBottle/edit/${drink._id}`}>Edit</Link></Button> | <Button variant="danger" onClick={(e) => {
                                            deleteDrinkBottle(e, drink._id)
                                        }}>Delete</Button></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

        </>

    )
}

export default EditDeleteBottle;